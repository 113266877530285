import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../../components/Layout'

import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import HeaderSEO from '../../components/partials/HeaderSEO'
import Form from '../../components/forms/Form'

import loader from '../../resources/images/spinner.gif'

import Logos from '../../components/partials/Logos9'

import { getEmailLocalStorage } from '../../helpers/getEmailStorage'
import { getCookieByName } from '../../helpers/getCookieByName'
import { initializeVideos } from '../../helpers/initializeVideo'

interface ResponseData {
  identifier: string
  downloadURL: string
  end: string
}

const lenses = () => {
  const data = useStaticQuery(graphql`
    query LensesBoxSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Form_Box" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  const [activeChoiceData, setActiveChoiceData] = useState({
    d_paragraph: 'All-in-one instance of Lenses & Kafka.',
    bullet1: 'Single docker container',
    bullet2: 'Installed in minutes',
    bullet3: 'Includes a working Kafka environment',
    bullet4: 'Pre-configured with sample data flows',
  })

  // Destructure

  const { d_paragraph, bullet1, bullet2, bullet3, bullet4 } = activeChoiceData

  const [loadingData, setLoadingData] = useState({
    loading: false,
    lambdaURL: '',
  })
  const { loading, lambdaURL } = loadingData

  // Read URL
  useEffect(() => {
    // Defer Vimeo Videos
    initializeVideos()
    try {
      let url_string = window.location.href
      var url = new URL(url_string)
      let source = url.searchParams.get('s')

      if (source) {
        if (source.startsWith('usecase')) {
          setActiveChoiceData({
            ...activeChoiceData,
            d_paragraph:
              'All-in-one instance of Lenses & Kafka. Perfect for exploring all the features of Lenses',
            bullet1: 'Single docker container',
            bullet2: 'Installed in minutes',
            bullet3: 'Includes a working Kafka environment',
            bullet4: 'Pre configured with sample data flows',
          })
        }
        if (source.startsWith('connector')) {
          setActiveChoiceData({
            ...activeChoiceData,
            d_paragraph:
              'Perfect for testing a connector and exploring the features of Lenses',
            bullet1: 'Single docker container, Installed in minutes',
            bullet2: 'Includes working Kafka and Connect cluster',
            bullet3: 'Pre configured with sample data flows',
            bullet4: 'Packaged with over 30 connectors',
          })
        }
      }
      let emailStorage = getEmailLocalStorage()
      if (emailStorage) {
        document.querySelector<HTMLInputElement>('#email').value = emailStorage
      }
    } catch (err) {
      console.log(err)
    }
    if (location.href.includes('https://www.dev.lenses.io/')) {
      setLoadingData({
        ...loadingData,
        lambdaURL:
          'https://384in5y2mk.execute-api.eu-west-1.amazonaws.com/dev/api/v1/leads',
      })
    } else {
      setLoadingData({
        ...loadingData,
        lambdaURL:
          'https://s1qhfrb27e.execute-api.eu-west-1.amazonaws.com/prod/api/v1/leads',
      })
    }
    // es-lint-disable-next-line
  }, [])

  const handleFormSubmit = (e) => {
    e.preventDefault()
    document.querySelector<HTMLInputElement>('#splunk_id').value =
      getCookieByName('mintjs%3Auuid')
    document.querySelector<HTMLInputElement>('#timestamp').value =
      new Date().toLocaleString()
    if (
      document.querySelector<HTMLInputElement>('#terms_of_use').checked &&
      document.querySelector<HTMLInputElement>('#email').value !== '' &&
      document.querySelector<HTMLInputElement>('#first_name').value !== '' &&
      document.querySelector<HTMLInputElement>('#last_name').value !== ''
    ) {
      document.querySelector<HTMLInputElement>('#formsubmit').disabled = true
      setLoadingData({
        ...loadingData,
        loading: true,
      })
      let emailValue = document.querySelector<HTMLInputElement>('#email').value
      let companyValue = 'Not provided'
      axios
        .post(`${lambdaURL}`, {
          email: emailValue,
          source: 'website',
          company: companyValue,
          license: 'box',
        })
        .then(function (res) {
          const data = res.data as ResponseData
          document.querySelector<HTMLInputElement>('#license').value =
            data.identifier
          document.querySelector<HTMLInputElement>('#downloadURL').value =
            data.downloadURL
          document.querySelector<HTMLInputElement>('#expiration_date').value =
            data.end
          document.querySelector<HTMLFormElement>('#boxForm').submit()
        })
        .catch(function (err) {
          console.log(err)
          window.location.href = '/backend-error/'
        })
    }
  }

  return (
    <Layout onlyLogo={true}>
      <HeaderSEO pageSEO={pageSEO} />
      <Helmet>
        <link rel="canonical" href="https://lenses.io/downloads/lenses/" />
      </Helmet>
      {loading && (
        <section className="pt-5 " style={{ marginTop: '250px' }}>
          <div className="text-center primary-text fw-900 f-20">
            Please wait...
          </div>
          <div className="text-center pb-5 justify-content mb-5 mt-4">
            <img
              className=" pb-5"
              src={loader}
              alt="spinner"
              style={{ marginBottom: '1000px' }}
            />
          </div>
        </section>
      )}

      <section>
        <div className="container-1 hero-margin mb-4">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-lg-5 col-10 col-md-9 pt-5">
              <h1 id="heading-title">
                Run <br /> all-in-one Box, it&apos;s free!
              </h1>
              <p className="hero-description-dark pb-0" id="d-paragraph">
                {d_paragraph}
              </p>
              <p className="hero-description-dark pt-0">
                <i
                  className="fa fa-check ml-3 primary-color pr-2"
                  aria-hidden="true"
                ></i>
                {bullet1} <br />
                <i
                  className="fa fa-check ml-3 primary-color pr-2"
                  aria-hidden="true"
                ></i>
                {bullet2}
                <br />
                <i
                  className="fa fa-check ml-3 primary-color pr-2"
                  aria-hidden="true"
                ></i>
                {bullet3}
                <br />
                <i
                  className="fa fa-check ml-3 primary-color pr-2"
                  aria-hidden="true"
                ></i>
                {bullet4}
                <br />
              </p>
              <div className="cloud-aws-msk-video mt-5">
                <iframe
                  src=""
                  data-src="//player.vimeo.com/video/624320966"
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                  }}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="px-0 col-lg-4 offset-lg-2 offset-0 col-sm-7 col-10 pt-5 mobile-margin-bottom">
              <div className="footer-color py-5 px-2 rounded">
                <div className="d-flex flex-wrap">
                  <div className="col-md-12 mobile-padding-reset">
                    <Form
                      formId="boxForm"
                      action="https://go.pardot.com/l/877242/2020-08-04/4fkwlf"
                      onSubmit={(e) => handleFormSubmit(e)}
                      inputId="formsubmit"
                      inputValue="Get Docker Command"
                      firstName={'required'}
                      lastName={'required'}
                      email={'required'}
                      type={'box'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-2 mb-5 pt-3">
        <div className="container-1">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="paragraph-title pt-5">
                Trusted by over 25,000 developers
              </h2>
            </div>
            <Logos />
          </div>
        </div>
      </section>

      <section className="pb-5 mb-5">
        <div className="container-1">
          <div className="row mt-4">
            <div className="col-md-8 offset-md-2">
              <div className="row">
                <div className="col-md-6 p-5">
                  <h2 className="card-title paragraph-title p-0">
                    Get a Live Demo
                  </h2>
                  <p className="card-text">
                    Schedule a one-on-one live technical demo of Lenses.
                  </p>
                  <a href="/contact/lenses-demo/" className=" link-text">
                    Request Now →
                  </a>
                </div>
                <div className="col-md-6 p-5">
                  <h2 className="card-title paragraph-title p-0">
                    Connect to your Kafka?
                  </h2>
                  <p className="card-text">
                    Want to connect to your cluster or try on cloud?
                  </p>
                  <a href="/contact-us/" className=" link-text">
                    Contact us →
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default lenses
